import React, {useState} from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import Employee from "../components/Employee";
import {useMediaQuery} from 'react-responsive'
import CoreValue from "../components/CoreValue";

export const AboutUsTemplate = ({title, employees, corevalues}) => {
  const [selectedTab, setSelectedTab] = useState('people');
  const [index, setIndex] = useState(0);

  function isActive(tabName) {
    return selectedTab === tabName;
  }


  function next() {
    setIndex((index + 1) % employees.length);
  }

  function previous() {
    const newIndex = index - 1;
    setIndex(newIndex < 0 ? employees.length - 1 : newIndex);
  }

  const isMobile = useMediaQuery({query: '(max-width: 730px)'});
  const visibleEmployees = isMobile ? [employees[index]] : employees;
  return (
    <div className="container about-us-page">
      <h1 className="title">{title}</h1>
      <div className="tabs">
        <div className="tab-header">
          <button className={isActive('people') ? "active is-transparent" : "is-transparent"}
                  onClick={() => setSelectedTab('people')}>
            People Behind Littler
          </button>
          <button className={isActive('values') ? "active is-transparent" : "is-transparent"}
                  onClick={() => setSelectedTab('values')}>
            Core Values
          </button>
        </div>
        <div className="tab-indicator" style={{left: `calc(calc(100% / 2) * ${isActive('people') ? 0 : 1})`}}/>
        <div className="tab-body">
          <div className={isActive('people') ? "active cover" : "cover"}
               style={{order: isActive('people') ? 1 : 2}}>
            {visibleEmployees.map(e => <Employee employee={e} key={e.name} isMobile={isMobile} next={next}
                                                 previous={previous}/>)}
          </div>
          <div className={isActive('values') ? "active cover" : "cover"}
               style={{order: isActive('values') ? 1 : 2}}>
            {corevalues.map((value, index) => <CoreValue value={value} index={index + 1}/>)}
          </div>
        </div>
      </div>
    </div>
  )
}


const AboutUs = ({data}) => {
  const {frontmatter} = data.markdown;
  return (
    <Layout>
      <AboutUsTemplate
        title={frontmatter.title}
        employees={frontmatter.employee}
        corevalues={frontmatter.corevalues}
      />
    </Layout>
  )
}

export default AboutUs

export const pageQuery = graphql`
  query AboutUsTemplate {
    markdown: markdownRemark(
      frontmatter: { templateKey: { eq: "about-us" } }
    ) {
       frontmatter {
        title
         corevalues {
          title
          content
        }
        employee {
          name
          nickname
          position
          contacttext
          email
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          content
        }
       }
    }
  }
`
