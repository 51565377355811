import React from 'react';
import showdown from 'showdown';

const converter = new showdown.Converter();
export default function ({value, index}) {
  const html = converter.makeHtml(value.content);
  return <div className={'core-value'}>
    <div className={'core-value-index-desktop'}>0{index}</div>
    <div>
      <div className={'core-value-title-cover'}>
        <div className={'core-value-index'}>0{index}</div>
        <h3 className={'core-value-title'}>{value.title}</h3>
      </div>
      <div className={'content'} dangerouslySetInnerHTML={{__html: html}}/>
    </div>
  </div>
}
