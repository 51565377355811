import React, {useState} from 'react';
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import showdown from 'showdown';
import leftIcon from "../img/Icon-left.png";
import rightIcon from "../img/Icon-right.png";
/* eslint-disable */
const converter = new showdown.Converter()
export default function ({employee, previous, next, isMobile}) {
  const [showMore, setShowMore] = useState(false);

  function handleShowMore(e) {
    e.preventDefault();
    e.stopPropagation();
    setShowMore(!showMore);
  }

  const html = converter.makeHtml(employee.content);
  const showLess = {maxHeight: '460px', overflow: 'hidden'};

  return <div>
    <div className={showMore ? 'employee bottom-border' : 'employee'}>
      <div className={'image'}>
        {isMobile && <button onClick={previous} className="is-transparent left">
          <img src={leftIcon} alt="left"/>
        </button>}
        <div className={'image-cover'}>
          <PreviewCompatibleImage imageInfo={employee} alt={employee.name}/>
        </div>
        {!isMobile && <p className={'image-caption'}>{employee.position}</p>}
        {isMobile && <button onClick={next} className="is-transparent right">
          <img src={rightIcon} alt="right"/>
        </button>}
      </div>
      <div className={'description'}>
        <p className={'name'}>{employee.name}</p>
        {employee.position && <p className={'position'}>{employee.position}</p>}
        <div className={'content'} style={showMore || isMobile ? {maxHeight: '3500px', overflow: 'hidden'} : showLess}>
          <div dangerouslySetInnerHTML={{__html: html}}/>

          <p className={'email'}>{employee.contacttext} <a
            href={`mailto:${employee.email}`}>{employee.email}</a></p>
        </div>
      </div>
    </div>
    {!isMobile &&
    <div className={showMore ? 'show-less' : 'show-more'} onClick={handleShowMore}>{showMore ? '' : 'read more'}</div>}

  </div>;
}
